import { useState } from 'react';
// React-Router-Dom
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
// Assets
import { ReactComponent as PlusIcon } from "assets/images/serviceBuilder/plus.svg";
//-------------------------------------------------------------------------------------------------------------

const ServicesMainTabs = ({ tabs }) => {

  //*** */ Hooks
  const history = useHistory();
  const { serviceName, status } = useParams();
    
  //***  State
  const [selectedTab, setSelectedTab] = useState(serviceName);


  const isEditMode = status === "edit";

  // --------- JSX Code ----------- 
  return (
    <div className='main-tabs-holder'>
      {tabs?.map(tab => (
        <div
          key={tab?.id}
          className={`${selectedTab === tab?.url ? 'active-tab-box' : 'tab-box'} `}
          onClick={() => {
            if (!isEditMode) {
              setSelectedTab(tab?.url);
              history.push(`/website/service-builder/${tab?.url}/add`);
            }
          }}
          style={{ opacity: isEditMode && selectedTab !== tab?.url ? 0.7 : 1, pointerEvents: isEditMode && selectedTab !== tab?.url ? 'none' : 'auto' }} 
        >
          <div className='tab'>
            <span>{tab?.icon}</span>
            <p>{tab?.title}</p>
          </div>
          {selectedTab !== tab?.url ? <PlusIcon /> : <div style={{ width: '20px', height: '20px' }} />}
        </div>
      ))}
    </div>
  );
}

export default ServicesMainTabs;
