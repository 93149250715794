import { useState } from 'react';
// React-Router-Dom
import { useHistory } from 'react-router-dom';
// Translation
import Locale from 'translations';
// React-Switch
import Switch from "react-switch";
// Assets
import { ReactComponent as HotelIcon } from "assets/images/serviceBuilder/hotel.svg";
import { ReactComponent as MoreIcon } from "assets/images/serviceBuilder/threeDot.svg";
import { ReactComponent as RightAngleIcon } from "assets/images/serviceBuilder/rightAngle.svg";
import { ReactComponent as TrashIcon } from "assets/images/serviceBuilder/trash.svg";
import { ReactComponent as FlightIcon } from "assets/images/serviceBuilder/flight.svg";
import { ReactComponent as TransferIcon } from "assets/images/serviceBuilder/transfer.svg";
import { ReactComponent as AttractionIcon } from "assets/images/serviceBuilder/attraction.svg";
import { ReactComponent as PackageIcon } from "assets/images/serviceBuilder/package.svg";
import { ReactComponent as OthersIcon } from "assets/images/serviceBuilder/others.svg";
// React-BootStarp
import { OverlayTrigger, Popover } from 'react-bootstrap';
// Custom Hooks
import useSharedData from '../constant/useSharedData';
// -------------------------------------------------------------------------------------------------

const ServicesList = ({ servicesList, onDelete }) => {

  // Hook
  const { Service_Builder } = Locale;
  const history = useHistory()
  const { dir } = useSharedData()


  const popover = (id) => {
    return (
      <Popover
        id="popover-basic"
        className='pointer-event'
        onClick={() => onDelete(id)}
      >
        <Popover.Content >
          <p
            className='font-weight-bold  d-flex  align-items-center '
            style={{ color: '#EA5455' }}
          >
            <TrashIcon />
            <span className='ml-2'>{Service_Builder?.delete}</span>
          </p>
        </Popover.Content>
      </Popover >
    )
  }

  const [checked, setChecked] = useState()

  const handleSwitchChange = () => {
    setChecked(!checked)
  }

  const defineServiceIcon = (serviceType) => {
    if (serviceType === 'builder_hotel') {
      return <HotelIcon />
    }
    if (serviceType === 'builder_flight') {
      return <FlightIcon />
    }
    if (serviceType === 'builder_transfer') {
      return <TransferIcon />
    }
    if (serviceType === 'builder_attraction') {
      return <AttractionIcon />
    }
    if (serviceType === 'builder_tour') {
      return <AttractionIcon />
    }
    if (serviceType === 'builder_package') {
      return <PackageIcon />
    }
    if (serviceType === 'builder_other') {
      return <OthersIcon />
    }
  }

  const defineServiceName = (serviceType) => {
    if (serviceType === 'builder_hotel') {
      return 'hotel'
    }
    if (serviceType === 'builder_flight') {
      return 'flight'
    }
    if (serviceType === 'builder_transfer') {
      return 'transfer'
    }
    if (serviceType === 'builder_attraction') {
      return 'attraction'
    }
    if (serviceType === 'builder_tour') {
      return 'attraction'
    }
    if (serviceType === 'builder_package') {
      return 'packages'
    }
    if (serviceType === 'builder_other') {
      return 'other'
    }
  }

  // ---------- JSX Code --------
  return (
    <div className='services-list-holder'>
      <div className='operation-movements-table table-container'>

        <table className="table table-update mb-0">

          <thead>
            <tr style={{ background: '#F5F6F7' }}>
              <th>{Service_Builder?.ref}</th>
              <th>{Service_Builder?.service}</th>
              <th>{Service_Builder?.name}</th>
              <th>{Service_Builder?.price}</th>
              <th>{Service_Builder?.Enabled}</th>
              <th>{Service_Builder?.action}</th>
            </tr>
          </thead>

          <tbody>

            {servicesList?.length > 0 &&
              servicesList?.map((service) =>
                <tr key={service?.id}>

                  {/* ref */}
                  <td >
                    <div className='d-flex align-items-center'>
                      <span className='indecator'></span>
                      <div className='d-flex flex-column mx-2'>
                        {service?.id}
                      </div>
                    </div>
                  </td>

                  {/* serviceIcon */}
                  <td width="115px">
                    <div className="d-flex align-items-center">
                      <span className="font-light mx-1">
                        {defineServiceIcon(service?.type)}
                      </span>
                    </div>
                  </td>

                  {/* name */}
                  <td>
                    <p className='font-weight-bold'>
                      {service?.name}
                    </p>
                  </td>

                  {/* price */}
                  <td>
                    <p className='d-flex align-items-center'>
                      {service?.price} {service?.currency}
                    </p>
                  </td>

                  {/* enabled */}
                  <td className='from-color'>
                    <Switch
                      onChange={handleSwitchChange}
                      checked={service?.status === 'enabled'}
                      checkedIcon={false}
                      uncheckedIcon={false}
                      height={24}
                      width={44}
                    />
                  </td>

                  {/* Action */}
                  <td width="140px">
                    <div className='d-flex '>
                      <span className='pointer-event align-items-center'>
                        <OverlayTrigger rootClose={true} trigger="click" placement={`${dir === 'rtl' ? 'right' : 'left'}`} overlay={popover(service?.id)}>
                          <MoreIcon className='mr-1' />
                        </OverlayTrigger>
                      </span>

                      <span
                        className=' d-flex pointer-event align-items-center  gap-2'
                        onClick={() => history.push(`/website/service-builder/${defineServiceName(service?.type)}/edit/${service?.id}`)}
                        style={{
                          transform: `${dir === 'rtl' && 'rotate(180deg)'}`
                        }}
                      >
                        <RightAngleIcon />
                      </span>
                    </div>

                  </td>
                </tr>
              )}

            {servicesList?.length === 0 &&
              <tr>
                <td colSpan="10">
                  <div className="product-build__product-no-data fullHeight">
                    <i className="icx icx-eye-slash" aria-hidden="true"></i>
                    <h4>{Service_Builder?.no_data}</h4>
                  </div>
                </td>
              </tr>
            }

          </tbody>
        </table>

      </div>
    </div>
  )
}

export default ServicesList