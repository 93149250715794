import { useWebBuilderState } from 'context/webBuilder';
import { useState } from 'react';
import { ReactComponent as SettingIcon } from "assets/images/webBuilder/setting.svg";
import { EditBox } from 'modules/WebBuilder-V2/shared/EditBox';
import SocialMediaModal from './SocialMediaModal';
import { isObject } from "lodash"
import Locale from 'translations';


const URI = process.env.REACT_APP_FILEUPLOAD_URL + "/fetch";


export default function FooterThemeTen({ focusContainer, handleFocus, socialMediaIcons }) {
	const { webBuilder } = Locale;
	const { footer: footerDetails, hero, selectedLanguage } = useWebBuilderState();
	const type = "footer";
	const [socialMediaModal, setSocialMediaModal] = useState(false);


	// const services = ourServices?.services?.filter(service => service?.visible);
	const header = hero?.header;
	const logoImage = isObject(header?.logo) ?
		<img src={`${header?.logo[selectedLanguage].includes("http") ? header?.logo[selectedLanguage] : URI + "/" + header?.logo[selectedLanguage]}`} alt="Logo" />
		:
		<img src={`${header?.logo[selectedLanguage]}`} alt="Logo" />;


	function toggleSocialMediaModal() {
		setSocialMediaModal(!socialMediaModal);
	}


	const scrollToSection = (id) => {
		if (id) {
			const element = document.getElementById(id)
			element?.scrollIntoView({ behavior: 'smooth' })
		}
	}

	const containerBoxActions = [
		<span onClick={toggleSocialMediaModal} className="pointer">
			<SettingIcon />
		</span>,
	];


	return (
		<div className="container">
			<div className="footer-content">
				{/* right section */}
				<div className="d-flex flex-column gap-24">

					<div className="logo-container">
						{logoImage}
					</div>

					<div className="social-media" onClick={() => handleFocus(type)}>
						<div>
							<EditBox
								editMode={focusContainer === type}
								defaultIcon={containerBoxActions}
							>
								<ul className="links">
									{footerDetails?.links?.map((link) => {
										if (link?.visible) {
											return (
												<div key={link?.id}>
													{/* <a href={link?.url} target="_blank" rel="noreferrer"> */}
													{socialMediaIcons[link?.id]}
													{/* </a> */}
												</div>
											);
										} else {
											return null;
										}
									})}
								</ul>
							</EditBox>
						</div>
					</div>
				</div>

				<ul className='links-list'>
					<li className={'text-secondary-color'}
						onClick={() => scrollToSection('main-web-builder')}
					>
						{webBuilder.home}
					</li>

					<li className={'text-secondary-color'}
						onClick={() => scrollToSection('contact-us-section')}
					>
						{webBuilder.getHelp}
					</li>

					<li className={'text-secondary-color'}
						onClick={() => scrollToSection('our-service')}
					>
						{webBuilder.ourServices}
					</li>
				</ul>
			</div>


			{socialMediaModal && footerDetails?.links ? (
				<SocialMediaModal
					isOpen={socialMediaModal}
					toggle={toggleSocialMediaModal}
					header={"Social links"}
					socialMediaLinks={footerDetails?.links}
				/>
			) : null}
		</div>
	)
}