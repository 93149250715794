import { useWebBuilderState } from "context/webBuilder";
// import UploadFileComponent from "modules/WebBuilder-V2/shared/UploadFile";
import { ReactComponent as PhoneIcon } from "assets/images/webBuilder/call.svg";
// import { ReactComponent as LogoPlaceholder } from "assets/images/webBuilder/logo-placeholder.svg";
// import { useLocation } from "react-router-dom";
import HeaderButtonModal from "./Modals/HeaderButton";
import ChangeLogosModal from './Modals/ChangeLogos';
import useWBHeader from './useWBHeader';
import HeaderThemeOne from './Theme-1';
import HeaderThemeTwo from './Theme-2';
import HeaderThemeFour from './Theme-4';
import HeaderThemeFive from './Theme-5';
import HeaderThemeSeven from './Theme-7';
import ContactPhonesModal from './Modals/ContactPhonesModal';
import Locale from 'translations';




export default function WebBuilderHeader({ editHeader }) {
	const { webBuilder } = Locale;
	const { style } = useWebBuilderState();
	const {
		onSaveButton,
		saveCompanyLogos,
		toggleButtonModal,
		toggleLogoChangeModal,
		openButtonModal,
		updateHeroHeader,
		changeLogoModal,
		clickContactButton,
		contactPhonesOpen,
		toggleContactPhonesModal
	} = useWBHeader();

	const contactPhoneComponent = (
		<div className="d-flex align-items-center ml-2 call-us pointer" onClick={toggleContactPhonesModal}>
			<PhoneIcon color="#1e85ff" />
			<div className="webBuilder-contact d-flex flex-column ml-1">
				<p>{webBuilder.callUs}</p>
			</div>
		</div>
	)

	return (
		<>
			<div className="wb-header-container" onClick={editHeader}>

				{style?.theme === "theme-1" &&
					<HeaderThemeOne
						toggleLogoChangeModal={toggleLogoChangeModal}
						updateHeroHeader={updateHeroHeader}
						clickContactButton={clickContactButton}
						contactPhoneComponent={contactPhoneComponent}
					/>
				}

				{style?.theme === "theme-2" &&
					<HeaderThemeTwo
						toggleLogoChangeModal={toggleLogoChangeModal}
						updateHeroHeader={updateHeroHeader}
						clickContactButton={clickContactButton}
						contactPhoneComponent={contactPhoneComponent}
					/>
				}

				{style?.theme === "theme-3" &&
					<HeaderThemeOne
						toggleLogoChangeModal={toggleLogoChangeModal}
						updateHeroHeader={updateHeroHeader}
						clickContactButton={clickContactButton}
						contactPhoneComponent={contactPhoneComponent}
					/>
				}
				{(style?.theme === "theme-4" || style?.theme === "theme-10") &&
					<HeaderThemeFour
						toggleLogoChangeModal={toggleLogoChangeModal}
						updateHeroHeader={updateHeroHeader}
						clickContactButton={clickContactButton}
						contactPhoneComponent={contactPhoneComponent}
					/>
				}
				{style?.theme === "theme-5" &&
					<HeaderThemeFive
						toggleLogoChangeModal={toggleLogoChangeModal}
						updateHeroHeader={updateHeroHeader}
						clickContactButton={clickContactButton}
					/>
				}

				{style?.theme === "theme-7" &&
					<HeaderThemeSeven
						toggleLogoChangeModal={toggleLogoChangeModal}
						updateHeroHeader={updateHeroHeader}
						clickContactButton={clickContactButton}
						contactPhoneComponent={contactPhoneComponent}
					/>
				}
			</div>

			{/* contact us button modal */}
			{openButtonModal &&
				<HeaderButtonModal
					save={onSaveButton}
					isOpen={openButtonModal}
					toggle={toggleButtonModal}
				/>
			}

			{/* logo modal */}
			{changeLogoModal &&
				<ChangeLogosModal
					isOpen={changeLogoModal}
					toggle={toggleLogoChangeModal}
					save={saveCompanyLogos}
				/>
			}
			{contactPhonesOpen &&
				<ContactPhonesModal
					isOpen={contactPhonesOpen}
					toggle={toggleContactPhonesModal}
				// save={saveCompanyLogos}
				/>
			}

		</>
	);
}
