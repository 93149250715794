import { useWebBuilderState } from "context/webBuilder";
import { useHistory, useParams } from "react-router-dom";
import useWBHeader from './useWBHeader';
import WebBuilderNav from './MainNav';
import { ReactComponent as PhoneIcon } from "assets/images/webBuilder/call.svg";
import EditableParagraph from 'modules/WebBuilder-V2/shared/EditableParagraph';
import Locale from 'translations';



export default function HeaderThemeFive({ toggleLogoChangeModal, updateHeroHeader, clickContactButton }) {
	// *** hooks
	const { webBuilder } = Locale;
	const { selectedLanguage } = useWebBuilderState();

	const { bundle } = useParams();
	const history = useHistory();

	const {
		headerData,
		isPreview,
		logoImage,
	} = useWBHeader();



	return (
		<header className="wb-header">


			{/* nav */}
			<nav className="header-nav">
				{/* logo */}
				<div className="logo"
					onClick={() => {
						// check if we inside preview 
						if (isPreview) {
							history.push(`/${bundle}/web-builder-v2/preview`);
						} else {
							toggleLogoChangeModal();
						}
					}}
				>
					{logoImage}
				</div>

				<WebBuilderNav />
			</nav>

			{/* contact button */}
			<div className="d-flex-language-contact d-flex align-items-center gap-10">
				<button
					className="contact-button mr-2"
					onClick={() => {
						// check if we inside view package page when click scroll back to preview page the scroll using useeffect
						if (isPreview) {
							history.push({ pathname: `/${bundle}/web-builder-v2/preview`, state: headerData?.contactUs?.id });
						} else {
							clickContactButton()
						}
					}}
				>
					{headerData?.contactUs?.name?.[selectedLanguage] || headerData?.contactUs?.name}
				</button>

				<div className="d-flex align-items-center ml-2 call-us">
					<PhoneIcon color="#1e85ff" />
					<div className="webBuilder-contact d-flex flex-column ml-1">
						<p>{webBuilder.callUs}</p>
						<EditableParagraph
							className="body text-primary-web-builder"
							contentEditable={true}
							onInput={(value) => {
								updateHeroHeader({ name: "phoneNumber", value });
							}}
							text={headerData?.phoneNumber}
							preventTranslate={true}
						/>
					</div>
				</div>

			</div>



		</header>
	);
}
