// Translation
import Locale from 'translations';
// Helper
// import { createYearsList } from '../helpers/helper';
// -----------------------------------------------------------------



export default function useSharedData() {

  const { Service_Builder } = Locale

  const currentYear = new Date().getFullYear()

  // createYearsList take fromYear , toYear and return list of year {name , id}
  const createYearsList = (fromYear, toYear) => {
    const diff = Number(toYear) - Number(fromYear)
    let years = [...Array(diff + 1).keys()].map((e, i) => ({
      name: `${fromYear + i}`,
      id: `${fromYear + i}`
    }))
    return years
  }

  const vehiclesModel = createYearsList(2000, currentYear)

  const dir = localStorage.getItem('currentLocale') === 'ar' ? 'rtl' : 'ltr'

  const tags = [
    { label: Service_Builder.all, id: '' },
    { label: Service_Builder.hotel, id: 'builder_hotel' },
    { label: Service_Builder.flight, id: 'builder_flight' },
    { label: Service_Builder.transfer, id: 'builder_transfer' },
    { label: Service_Builder.attraction, id: 'builder_tour' },
    { label: Service_Builder.packages, id: 'builder_package' },
    { label: Service_Builder.others, id: 'builder_other' },
  ]

  const statusList = [
    { name: Service_Builder.disabled, id: 'disabled' },
    { name: Service_Builder.enabled, id: 'enabled' }
  ]

  const starsOptions = [
    { name: 1, id: '1' },
    { name: 2, id: '2' },
    { name: 3, id: '3' },
    { name: 4, id: '4' },
    { name: 5, id: '5' },
  ]

  const refundOptions = [
    { name: Service_Builder?.refundable, id: 1 },
    { name: Service_Builder?.non_refundable, id: 0 },
  ]

  const cyclesOptions = [
    { name: Service_Builder?.full_cycle, id: 'full_cycle' },
    { name: Service_Builder?.part_cycle, id: 'part_cycle' },
  ]

  const vehicleTypesOptions = [
    { name: Service_Builder?.sedan_car, id: '1' },
    { name: Service_Builder?.suv_car, id: '2' },
    { name: Service_Builder?.bus, id: '3' },
    { name: Service_Builder?.van, id: '4' },
  ]

  const driverOptions = [
    { name: Service_Builder?.with_driver, id: 1 },
    { name: Service_Builder?.without_driver, id: 0 },
  ]

  const tourTypesOptions = [
    { id: 'part_cycle', name: 'Part Cycle', value: 'part_cycle',  },
    { id: 'full_cycle', name: 'Full Cycle', value: 'full_cycle',  },
  ];

  const packageServicesOptions = [
    { name: Service_Builder?.hotel, id: 'builder_hotel' },
    { name: Service_Builder?.flight, id: 'builder_flight' },
    { name: Service_Builder?.transfer, id: 'builder_transfer' },
    { name: Service_Builder?.attraction, id: 'builder_tour' },
  ]

  // required hotel form
  const requiredHotelInputs = [
    "name_ar",
    "name_en",
    "country",
    'city',
    'hotel_name_en',
    'hotel_stars',
    'hotel_address',
    "hotel_amenities",
    "refundable",
    "images",
    "policy_en",
    "cancellation_en",
    "description_en",
  ]

  // required flight form
  const requiredFlightInputs = [
    "name_ar",
    "name_en",
    "destination",
    'description_en',
    'images',
  ]

  // required transfer form
  const requiredUmrahInputs = [
    "trip_type",
    "name_ar",
    "name_en",
    'description_en',
    'policy_en',
    'car_capacity',
    'model',
    'vehicleType',
    'cycle',
    'refundable',
    'withDriver',
    'images',
  ]

  const requiredTourismInputs = [
    "trip_type",
    "name_ar",
    "name_en",
    'description_en',
    'policy_en',
    'car_capacity',
    'model',
    'vehicleType',
    'country',
    'city',
    'refundable',
    'withDriver',
    'images',
  ]

  // required attraction form
  const requiredAttractionInputs = [
    "name_en",
    "name_ar",
    "country",
    "city",
    "tour_type",
    "stars",
    "address",
    "inclusions",
    "exclusions",
    "description_en",
    "policy_en",
    "images",
  ]

  // required attraction form
   const requiredOthersInputs = [
    "name_en",
    "name_ar",
    "address",
    "inclusions",
    "exclusions",
    "description_en",
    "policy_en",
    "images",
  ]

  // required package form
  const requiredPackageInputs = [
    "name_ar",
    "name_en",
    "productType",
    'services',
    'stars',
    'inclusions',
    'images',
    'exclusions',
    "policy_en",
    "description_en",
  ]





  // export function isPayloadValid(obj) {
  //   return Object.values(obj).every(v => v.length > 0);
  // }

  // Check if object have any empty value in its Properties value and return false if any poroety has value  ('' Or [] Or {})
  function isPayloadValid(obj = {}) {
    // console.log(obj)
    for (const value of Object.values(obj)) {

      if (typeof value === 'object' && value !== null) {

        if (!isPayloadValid(value)) {
          return false;
        }

      }

      else {

        if (value === '' || value?.length === 0) {
          return false;
        }

        if (value === null) {
          return true;
        }

      }

    }
    return true;
  }


  // function to prepare builder_hotel List to send in packageService body request
  const prepareBuilderHotels = (hotelsList) => {
    const builder_hotel = hotelsList?.map(hotelItem => (
      {
        name: {
          en: hotelItem?.name_en,
          ar: hotelItem?.name_ar
        },
        country_id: hotelItem?.country?.id,
        city_id: hotelItem?.city?.id,
        hotel_name: {
          en: hotelItem?.hotel_name_en,
          ar: hotelItem?.hotel_name_ar
        },
        hotel_stars: hotelItem?.hotel_stars?.id,
        hotel_address: hotelItem?.hotel_address,
        hotel_amenities: hotelItem?.hotel_amenities,
        is_refundable: hotelItem?.refundable?.id,
        policy: {
          en: hotelItem?.policy_en,
          ar: hotelItem?.policy_ar
        },
        cancellation: {
          en: hotelItem?.cancellation_en,
          ar: hotelItem?.cancellation_ar
        },
        description: {
          en: hotelItem?.description_en,
          ar: hotelItem?.description_ar
        },
        images: hotelItem?.images,
        nights_count: 1
      }
    ))
    if (isPayloadValid(builder_hotel?.[0])) {
      return { builder_hotel: builder_hotel }
    } else {
      return
    }
  }

  // function to prepare builder_flight List to send in packageService body request
  const prepareBuilderFlight = (flightsList) => {

    const builder_flight = flightsList?.map(flightItem => ({
      name: {
        en: flightItem?.name_en,
        ar: flightItem?.name_ar
      },
      destination_id: flightItem?.destination?.id,
      transporter_id: null,
      from_port_id: flightItem?.from_port?.id,
      to_port_id: flightItem?.to_port?.id,
      description: {
        en: flightItem?.description_en,
        ar: flightItem?.description_ar
      },
      images: flightItem?.images,
    }))
    if (isPayloadValid(builder_flight?.[0])) {
      return { builder_flight: builder_flight }
    } else {
      return
    }
  }

  // function to prepare builder_transfer List to send in packageService body request
  const prepareBuilderTransfer = (transfersList) => {
    const builder_transfer = transfersList?.map(transferItem => ({
      name: {
        en: transferItem?.name_en,
        ar: transferItem?.name_ar
      },
      trip_type: transferItem?.trip_type,
      country_id: transferItem?.country?.id,
      city_id: transferItem?.city?.id,
      cycle_type: transferItem?.cycle?.id,
      vehicle_type: transferItem?.vehicleType?.id,
      vehicle_model: transferItem?.model?.id,
      car_capacity: transferItem?.car_capacity,
      refundable: transferItem?.refundable?.id,
      with_driver: transferItem?.withDriver?.id,
      description: {
        en: transferItem?.description_en,
        ar: transferItem?.description_ar
      },
      policy: {
        en: transferItem?.policy_en,
        ar: transferItem?.policy_ar
      },
      images: transferItem?.images,
    }))
    if (isPayloadValid(builder_transfer?.[0])) {
      return { builder_transfer: builder_transfer }
    } else {
      return
    }
  }

  // function to prepare builder_tour List to send in packageService body request
  const prepareBuilderAttraction = (attractionsList) => {
    const builder_attraction = attractionsList?.map(attractionItem => {
      // if (isPayloadValid(attractionItem)) {
      return ({
        name: {
          en: attractionItem?.name_en,
          ar: attractionItem?.name_ar
        },
        destination_id: attractionItem?.destination?.id,
        country_id: attractionItem?.country?.id,
        city_id: attractionItem?.city?.id,
        tour_type: attractionItem?.tour_type?.id,
        stars: attractionItem?.stars?.id,
        address: attractionItem?.address,
        inclusion: attractionItem?.inclusions,
        exclusion: attractionItem?.exclusions,
        description: {
          en: attractionItem?.description_en,
          ar: attractionItem?.description_ar || 'pol ar'
        },
        policy: {
          en: attractionItem?.policy_en,
          ar: attractionItem?.policy_ar || 'pol ar'
        },
        images: attractionItem?.images,
      })
      // } else {
      //   return null;
      // }
    })

    if (isPayloadValid(builder_attraction?.[0])) {
      return { builder_tour: builder_attraction }
    } else {
      return
    }
  }

  // function to define service Name according to service type
  const defineServiceName = (serviceType) => {
    if (serviceType === 'builder_hotel') {
      return Service_Builder?.hotel
    }
    if (serviceType === 'builder_flight') {
      return Service_Builder?.flight
    }
    if (serviceType === 'builder_transfer') {
      return Service_Builder?.transfer
    }
    if (serviceType === 'builder_tour') {
      return Service_Builder?.attraction
    }
  }

  // function to prepare servicesOPtions which come from viewPackage api to show in multiSelect input
  const servicesToShow = (itemsList) => {
    const servicesBuilders = new Map(itemsList?.map(e => [e.type, e]))
    const uniqueServicesBuilders = [...servicesBuilders.values()].map(item => ({
      name: defineServiceName(item?.type),
      id: item?.type,
      label: defineServiceName(item?.type),
    }))
    return uniqueServicesBuilders
  }

  // function to prepare builder_hotel List to which come from items Property in viewPackage api 
  // to show in hotel Section in package form
  const prepareShowBuilderHotel = (itemsList) => {
    const hotelBuilderItems = itemsList?.filter(item => item?.type === 'builder_hotel')
    const builderHotelToShow = hotelBuilderItems?.map(row => ({
      id: row?.item?.id,
      name_en: row?.item?.name?.en,
      name_ar: row?.item?.name?.ar,
      country: { id: row?.item?.country?.country_id, name: row?.item?.country?.name },
      city: row?.item?.city,
      hotel_name_en: row?.item?.hotel_name,
      hotel_name_ar: null,
      hotel_stars: { id: row?.item?.hotel_stars, name: row?.item?.hotel_stars },
      hotel_address: row?.item?.hotel_address,
      hotel_amenities: row?.item?.hotel_amenities,
      refundable: refundOptions?.find(e => e?.id === row?.item?.is_refundable),
      policy_en: row?.item?.policy,
      policy_ar: null,
      cancellation_en: row?.item?.cancellation,
      cancellation_ar: null,
      description_en: row?.item?.description,
      description_ar: null,
      images: row?.item?.images,
      nights_count: 1,
    }))
    return builderHotelToShow?.length > 0 ? builderHotelToShow : null
  }

  // function to prepare builder_flight List to which come from items Property in viewPackage api
  // to show in flight Section in package form
  const prepareShowBuilderFlight = (itemsList) => {
    const flightBuilderItems = itemsList?.filter(item => item?.type === 'builder_flight')
    const builderFlightToShow = flightBuilderItems?.map(row => ({
      id: row?.item?.id,
      name_en: row?.item?.name?.en,
      name_ar: row?.item?.name?.ar,
      destination: { id: row?.item?.destination?.country_id, name: row?.item?.destination?.name },
      from_port: row?.item?.from_port,
      to_port: row?.item?.to_port,
      description_en: row?.item?.description,
      description_ar: null,
      images: row?.item?.images,
    }))
    return builderFlightToShow?.length > 0 ? builderFlightToShow : null
  }

  // function to prepare builder_transfer List to which come from items Property in viewPackage api
  // to show in transfer Section in package form
  const prepareShowBuilderTransfer = (itemsList) => {
    const transferBuilderItems = itemsList?.filter(item => item?.type === 'builder_transfer')
    const builderTransferToShow = transferBuilderItems?.map(row => ({
      id: row?.item?.id,
      trip_type: row?.item?.trip_type,
      name_en: row?.item?.name?.en,
      name_ar: row?.item?.name?.ar,
      country: row?.item?.country,
      city: row?.item?.city,
      cycle: cyclesOptions?.find(item => item?.id === row?.item?.cycle_type),
      vehicleType: vehicleTypesOptions?.find(item => Number(item?.id) === row?.item?.vehicle_type),
      model: {
        name: row?.item?.vehicle_model,
        id: row?.item?.vehicle_model
      },
      refundable: refundOptions?.find(e => e.id === row?.item?.refundable),
      car_capacity: row?.item?.car_capacity,
      withDriver: driverOptions?.find(item => item.id === row?.item?.with_driver),
      policy_en: row?.item?.policy,
      policy_ar: null,
      description_en: row?.item?.description,
      description_ar: null,
      images: row?.item?.images,
    }))
    return builderTransferToShow?.length > 0 ? builderTransferToShow : null
  }

  // function to prepare builder_tour List to which come from items Property in viewPackage api
  // to show in attraction Section in package form
  const prepareShowBuilderAttraction = (itemsList) => {
    const AttractionBuilderItems = itemsList?.filter(item => item?.type === 'builder_tour')
    const builderAttractionToShow = AttractionBuilderItems?.map(row => ({
      id: row?.item?.id,
      name_en: row?.item?.name?.en,
      name_ar: row?.item?.name?.ar,
      country: row?.item?.country,
      city: row?.item?.city,
      policy_en: row?.item?.policy,
      policy_ar: null,
      description_en: row?.item?.description,
      description_ar: null,
      tour_type: tourTypesOptions?.find(e => e?.id === row?.item?.tour_type),
      stars: { id: row?.item?.stars, name: row?.item?.stars },
      address: row?.item?.address,
      inclusions: row?.item?.inclusion,
      exclusions: row?.item?.exclusion,
      images: row?.item?.images,
    }))
    return builderAttractionToShow?.length > 0 ? builderAttractionToShow : null
  }

  const diffTwoArraysOfObjects = (firstArray, secondArray, diffProperty) => {
    return firstArray.filter(({ [diffProperty]: id1 }) => !secondArray?.some(({ [diffProperty]: id2 }) => id2 === id1))
  }


  return {
    // Constant
    currentYear,
    tags,
    statusList,
    vehiclesModel,
    starsOptions,
    refundOptions,
    cyclesOptions,
    vehicleTypesOptions,
    driverOptions,
    tourTypesOptions,
    packageServicesOptions,
    requiredHotelInputs,
    requiredFlightInputs,
    requiredUmrahInputs,
    requiredTourismInputs,
    requiredAttractionInputs,
    requiredOthersInputs,
    requiredPackageInputs,
    dir,
    // Function
    isPayloadValid,
    prepareBuilderHotels,
    prepareBuilderFlight,
    prepareBuilderTransfer,
    prepareBuilderAttraction,
    defineServiceName,
    servicesToShow,
    prepareShowBuilderHotel,
    prepareShowBuilderFlight,
    prepareShowBuilderTransfer,
    prepareShowBuilderAttraction,
    diffTwoArraysOfObjects,
  }

}
