import { useEffect, useRef, useState } from 'react'
// react-router-dom
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
// Translation
import Locale from 'translations';
// Components
import SelectField from 'components/Form/SelectField/SelectField';
import TextField from 'components/Form/TextField/TextField';
import TextAreaField from 'components/Form/TextAreaField/TextAreaField';
import AutoCompleteField from 'components/Form/AutoCompleteField/AutoCompleteField';
import UploadFile from 'components/UploadFile';
// Context
import { useSBSState } from 'context/global';
// Shared Components
import MultiTagsTextField from '../shared/MultiTagsTextField';
import HotelPricesTaxes from '../shared/HotelPricesTaxes';
// Servicies
import { getHotelMapToolHotels } from 'services/productbuilder';
import { fetchCities } from 'services/lookups';
import { addHotelService, editHotelService, getServicesById } from 'services/serviveBuilder';
// Helper
import validate, { isFormValid } from 'helpers/validate';
import generateUniqueID from 'helpers/generateUniqueID';
// Assets
import { ReactComponent as CloseIcon } from "assets/images/serviceBuilder/x.svg";
// Custom Hook
import useSharedData from '../constant/useSharedData';
import useDidMountEffect from 'hooks/useDidMountEffect ';
// ----------------------------------------------------------------------------------------------------

const ServiceBuilderHotelForm = () => {

  // *** Hooks
  const { Service_Builder, commons, inventory } = Locale

  const { locale, allCountries } = useSBSState()

  const { refundOptions, requiredHotelInputs, starsOptions, isPayloadValid } = useSharedData()

  const history = useHistory()

  const { status, id } = useParams()

  let goingToPrevValue = useRef(null);

  // *** State
  const [hotelData, setHotelData] = useState({
    serviceId: '',
    name_en: '',
    name_ar: '',
    country: '',
    city: '',
    hotel_name_en: '',
    hotel_name_ar: '',
    hotel_stars: '',
    hotel_address: "",
    hotel_amenities: [],
    refundable: '',
    policy_en: '',
    policy_ar: '',
    cancellation_en: '',
    cancellation_ar: '',
    description_en: '',
    description_ar: '',
    images: [],
    nights_count: 1,
    price: [{
      "room_type": '',
      "adult_price": '',
      "child_price": ''
    }],
    currency: "SAR",
    taxes: [{
      name: '',
      type: '',
      value: '',
    }]
  });

  const [hotelAmenity, setHotelAmenity] = useState('')

  const [showPricing, setShowPricing] = useState(false)

  const [citiesList, setCitiesList] = useState([])

  const [hotelsList, setHotelsList] = useState([]);

  const [errors, setErrors] = useState({});

  // *** Function Logic
  function handleHotelInputsChanges({ key, value }) {
    let hotelDataClone = { ...hotelData }
    hotelDataClone[`${key}`] = value
    if (key === 'country') {
      fetchCity(value?.id)
      setHotelData({
        ...hotelDataClone,
        city: '',
        hotel_name_en: '',
        hotel_stars: '',
        hotel_address: '',
        hotel_amenities: [],
      })
    } else {
      setHotelData({ ...hotelDataClone })
    }
  }

  function checkFormErrors() {
    let submitError = {};
    requiredHotelInputs.forEach(key => {
      submitError = {
        ...submitError,
        ...validate(
          { name: key, value: hotelData[key] },
          { required: true }
        )
      }
    })
    setErrors(() => submitError);
    return submitError;
  }

  const handleAddHotelAmenity = () => {
    const hotelDataClone = { ...hotelData }
    if (hotelAmenity !== '') {
      setHotelData({
        ...hotelDataClone,
        hotel_amenities: [...hotelDataClone?.hotel_amenities, hotelAmenity]
      })
      setHotelAmenity('')
    }
  }

  const handleRemoveHotelAmenity = (index) => {
    const hotelDataClone = { ...hotelData }
    hotelDataClone?.hotel_amenities?.splice(index, 1)
    setHotelData({ ...hotelDataClone })
  }

  // AutoCompleteField hotels list
  async function getHotelsList(inputValue) {
    if (inputValue.length > 2) {
      let params = {
        name: inputValue,
        country_id: hotelData?.country?.id,
        city_id: hotelData?.city?.id,
      }
      const hotelsRes = await getHotelMapToolHotels(params);
      let result = [];
      hotelsRes?.data?.forEach((item) => {
        result.push({
          ...item,
          name: item.name?.[locale] || item.name,
          value: item.id,
          label: item.name?.[locale] || item.name,
        });
      });
      setHotelsList(result);
    }
  };

  const fetchCity = async (countryId) => {
    const citiesRes = await fetchCities(countryId);
    setCitiesList(citiesRes);
  };

  const fetchHotelData = async () => {
    const response = await getServicesById(id)
    if (response?.status === 200) {
      setHotelData({
        serviceId: response?.data?.data?.service.id,
        name_en: response?.data?.data?.service?.name?.en,
        name_ar: response?.data?.data?.service?.name?.ar,
        country: response?.data?.data?.service?.country,
        city: response?.data?.data?.service?.city,
        hotel_name_en: response?.data?.data?.service?.hotel_name,
        hotel_name_ar: '',
        hotel_stars: { name: response?.data?.data?.service?.hotel_stars, id: response?.data?.data?.service?.hotel_stars },
        hotel_address: response?.data?.data?.service?.hotel_address,
        hotel_amenities: response?.data?.data?.service?.hotel_amenities,
        refundable: refundOptions?.find(e => e.id === response?.data?.data?.service?.is_refundable),
        policy_en: response?.data?.data?.service?.policy,
        policy_ar: '',
        cancellation_en: response?.data?.data?.service?.cancellation,
        cancellation_ar: '',
        description_en: response?.data?.data?.service?.description,
        description_ar: '',
        images: response?.data?.data?.service?.images,
        nights_count: response?.data?.data?.service?.nights_count,
        price: response?.data?.data?.service?.price || [],
        currency: response?.data?.data?.service?.currency,
        taxes: response?.data?.data?.service?.taxes || []
      })
      setShowPricing(response?.data?.data?.service?.price ? true : false)
    }
    return response
  }


  async function submit(e) {
    e.preventDefault();
    let formErrors = checkFormErrors();
    if (!isFormValid(formErrors)) {
      return
    }
    let requestBody = {
      name: {
        en: hotelData?.name_en,
        ar: hotelData?.name_ar,
      },
      country_id: hotelData?.country?.id || hotelData?.country?.country_id,
      city_id: hotelData?.city?.id,
      hotel_name: {
        en: hotelData?.hotel_name_en,
        ar: hotelData?.hotel_name_ar,
      },
      hotel_stars: hotelData?.hotel_stars?.id,
      hotel_address: hotelData?.hotel_address,
      hotel_amenities: hotelData?.hotel_amenities,
      is_refundable: hotelData?.refundable?.id,
      policy: {
        en: hotelData?.policy_en,
        ar: hotelData?.policy_ar,
      },
      cancellation: {
        en: hotelData?.cancellation_en,
        ar: hotelData?.cancellation_ar,
      },
      description: {
        en: hotelData?.description_en,
        ar: hotelData?.description_ar,
      },
      images: hotelData?.images,
      nights_count: hotelData?.nights_count,
      // price:  hotelData?.price,
      price: isPayloadValid(hotelData?.price?.[0]) ? hotelData?.price : null,
      currency: hotelData?.currency,
      // taxes:  hotelData?.taxes ,
      taxes: isPayloadValid(hotelData?.taxes?.[0]) ? hotelData?.taxes : null
    }
    if (status === 'add') {
      const response = await addHotelService(requestBody);
      if (response?.status === 200 || response?.status === 201) {
        history.push('/website/service-builder')
      }
    } else if (status === 'edit') {
      const response = await editHotelService(hotelData?.serviceId, requestBody);
      if (response?.status === 200 || response?.status === 201) {
        history.push('/website/service-builder')
      }
    }
  }


  useEffect(() => {
    if (status === 'edit' && id) {
      fetchHotelData()
    }
  }, [])

  useDidMountEffect(() => {
    if (hotelData?.hotel_amenities?.length >= 0) {
      setErrors({
        ...errors,
        ...validate(
          { name: "hotel_amenities", value: hotelData?.hotel_amenities?.length },
          { required: true }
        ),
      });
    }
  }, [hotelData?.hotel_amenities])

  console.log(hotelData)

  // -------- JSX Code ---------
  return (
    <div >
      <h1 className='form-title'>
        {Service_Builder?.select_hotel_details}
      </h1>

      <form onSubmit={submit} className="mt-4 ">

        {/* Hotel Service Form  */}
        <div className='row form-holder mx-2 mb-5'>

          {/* Service Name en */}
          <div className="col-4 custom-field  mt-3">
            <TextField
              type="text"
              hasLabel={(status === 'edit' ? true : false)}
              label={Service_Builder?.service_name_en}
              placeholder={Service_Builder?.service_name_en}
              name="name_en"
              id="name_en"
              value={hotelData?.name_en}
              onChange={(e) => {
                handleHotelInputsChanges({ key: 'name_en', value: e?.target?.value })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "name_en", value: e?.target?.value },
                    { required: true }
                  ),
                });
              }}
              color={errors?.name_en?.required ? "danger" : ""}
              errors={errors?.name_en}
            />
          </div>

          {/* Service Name ar */}
          <div className="col-4 custom-field mt-3">
            <TextField
              type="text"
              hasLabel={status === 'edit' ? true : false}
              label={Service_Builder?.service_name_ar}
              placeholder={Service_Builder?.service_name_ar}
              name="name_ar"
              id="name_ar"
              value={hotelData?.name_ar}
              onChange={(e) => {
                handleHotelInputsChanges({ key: 'name_ar', value: e?.target?.value })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "name_ar", value: e?.target?.value },
                    { required: true }
                  ),
                });
              }}
              color={errors?.name_ar?.required ? "danger" : ""}
              errors={errors?.name_ar}
            />
          </div>

          {/* Country */}
          <div className="col-2 mt-3">
            <SelectField
              hasLabel={status === 'edit' ? true : false}
              label={Service_Builder?.country}
              placeholder={Service_Builder?.country}
              id="country"
              name="country"
              value={hotelData?.country?.name}
              options={allCountries}
              onChange={(e) => {
                handleHotelInputsChanges({ key: 'country', value: e })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "country", value: e },
                    { required: true }
                  ),
                });
              }}
              color={errors?.country?.required ? "danger" : ""}
              errors={errors?.country}
            />
          </div>

          {/* City */}
          <div className="col-2  mt-3">
            <SelectField
              disabled={citiesList?.length === 0 && !hotelData?.country}
              hasLabel={status === 'edit' ? true : false}
              label={Service_Builder?.city}
              placeholder={Service_Builder?.city}
              id="city"
              name="city"
              value={hotelData?.city?.name}
              options={citiesList}
              onChange={(e) => {
                handleHotelInputsChanges({ key: 'city', value: e })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "city", value: e },
                    { required: true }
                  ),
                });
              }}
              color={errors?.city?.required ? "danger" : ""}
              errors={errors?.city}
            />
          </div>

          {/* Hotel Name */}
          <div className="col-4 custom-field mt-3">
            <div className="form-group">
              <AutoCompleteField
                hasLabel={status === 'edit' ? true : false}
                disabled={!hotelData?.city}
                labelInner={false}
                isImage={false}
                label={Service_Builder?.hotel_name}
                isSearchable={true}
                placeholder={Service_Builder?.hotel_name}
                listAuto={hotelsList}
                setListAuto={setHotelsList}
                getListAuto={getHotelsList}
                value={hotelData?.hotel_name_en || ""}
                // onFocus={(e) => clearSearchText(e, "name")}
                onBlur={() =>
                  setHotelData({
                    ...hotelData,
                    name: goingToPrevValue.current,
                  })
                }
                onChange={(e) => {
                  setHotelData({
                    ...hotelData,
                    hotel_name_en: e,
                  });
                }}
                onSelectValue={(selectedObj) => {
                  setHotelData({
                    ...hotelData,
                    hotel_name_en: selectedObj?.name,
                    hotel_stars: { name: selectedObj?.rating, id: selectedObj?.rating },
                    hotel_amenities: selectedObj?.facilities
                  });
                  setErrors({
                    ...errors,
                    ...validate({ name: "hotel_name_en", value: selectedObj }, { required: true }),
                  });
                }}
                color={errors?.hotel_name_en?.required ? "danger" : ""}
                errors={errors?.hotel_name_en}
              />
              <i class="fas fa-exchange-alt d-none"></i>
            </div>
          </div>

          {/* Hotel Star */}
          <div className="col-4  mt-3">
            <SelectField
              disabled={!hotelData?.hotel_name_en}
              hasLabel={status === 'edit' ? true : false}
              label={Service_Builder?.star}
              placeholder={Service_Builder?.star}
              id="hotel_stars"
              name="hotel_stars"
              value={hotelData?.hotel_stars?.id}
              options={starsOptions}
              onChange={(e) => {
                handleHotelInputsChanges({ key: 'hotel_stars', value: e })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "hotel_stars", value: e },
                    { required: true }
                  ),
                });
              }}
              color={errors?.hotel_stars?.required ? "danger" : ""}
              errors={errors?.hotel_stars}
            />
          </div>

          {/* Hotel Address*/}
          <div className="col-4 custom-field mt-3">
            <TextField
              disabled={!hotelData.hotel_name_en}
              type="text"
              hasLabel={status === 'edit' ? true : false}
              label={Service_Builder?.hotel_address}
              placeholder={Service_Builder?.hotel_address}
              name="hotel_address"
              id="hotel_address"
              value={hotelData?.hotel_address}
              onChange={(e) => {
                handleHotelInputsChanges({ key: 'hotel_address', value: e?.target?.value })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "hotel_address", value: e.target.value },
                    { required: true, email: true }
                  ),
                });
              }}
              color={errors?.hotel_address?.required ? "danger" : ""}
              errors={errors?.hotel_address}
            />
          </div>

          {/* Hotel Amenities*/}
          <div className="col-8  mt-3">
            <MultiTagsTextField
              hasLabel={true}
              type="text"
              label={Service_Builder?.hotel_amenities}
              placeholder={Service_Builder?.wifi_free}
              name="hotel_amenities"
              id="hotel_amenities"
              value={hotelAmenity}
              onChange={(e) => {
                setHotelAmenity(e.target.value)
              }}
              onClickAddButton={handleAddHotelAmenity}
              onEnter={(e) => {
                if (e.key === "Enter")
                  handleAddHotelAmenity();
              }}
              color={errors?.hotel_amenities?.required ? "danger" : ""}
              errors={errors?.hotel_amenities}
            />
            <div className='d-flex flex-wrap mt-2 '>
              {hotelData?.hotel_amenities?.map((amenity, index) =>
                <div className='tag mx-1 my-1' >
                  {amenity}
                  <span
                    className='pointer-event font-bold'
                    onClick={() => handleRemoveHotelAmenity(index)}
                  >
                    <CloseIcon />
                  </span>
                </div>
              )}
            </div>
          </div>

          {/* Hotel Refund*/}
          <div className="col-4 mt-3 align-self-baseline" style={{ height: '68px' }}>
            <SelectField
              hasLabel={false}
              label={Service_Builder?.refundable}
              placeholder={Service_Builder?.refundable}
              id="country"
              name="country"
              value={hotelData?.refundable?.name}
              options={refundOptions}
              onChange={(e) => {
                handleHotelInputsChanges({ key: 'refundable', value: e })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "refundable", value: e },
                    { required: true }
                  ),
                });
              }}
              color={errors?.refundable?.required ? "danger" : ""}
              errors={errors?.refundable}
            />
          </div>

          {/* Hotel Description*/}
          <div className="col-12 custom-field  mt-3">
            <TextAreaField
              hasLabel={status === 'edit' ? true : false}
              label={Service_Builder?.description}
              height={'126px'}
              placeholder={Service_Builder?.description}
              value={hotelData?.description_en}
              onChange={(e) => {
                handleHotelInputsChanges({ key: 'description_en', value: e?.target?.value })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "description_en", value: e?.target?.value },
                    { required: true }
                  ),
                });
              }}
              color={errors?.description_en?.required ? "danger" : ""}
              errors={errors?.description_en}
            />
          </div>

          {/* Hotel Policy*/}
          <div className="col-6 custom-field mt-3">
            <TextAreaField
              hasLabel={status === 'edit' ? true : false}
              height={'126px'}
              label={Service_Builder?.policy}
              placeholder={Service_Builder?.policy}
              value={hotelData?.policy_en}
              onChange={(e) => {
                handleHotelInputsChanges({ key: 'policy_en', value: e?.target?.value })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "policy_en", value: e?.target?.value },
                    { required: true }
                  ),
                });
              }}
              color={errors?.policy_en?.required ? "danger" : ""}
              errors={errors?.policy_en}
            />
          </div>

          {/* Hotel Canceltion*/}
          <div className="col-6 custom-field mt-3">
            <TextAreaField
              hasLabel={status === 'edit' ? true : false}
              height={'126px'}
              label={Service_Builder?.cancellation}
              placeholder={Service_Builder?.cancellation}
              value={hotelData?.cancellation_en}
              onChange={(e) => {
                handleHotelInputsChanges({ key: 'cancellation_en', value: e?.target?.value })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "cancellation_en", value: e?.target?.value },
                    { required: true }
                  ),
                });
              }}
              color={errors?.cancellation_en?.required ? "danger" : ""}
              errors={errors?.cancellation_en}
            />
          </div>

          {/* Hotel Iamges*/}
          <div className="col-12 mt-3">
            {/* <p className='font-bold '>{Service_Builder?.add_images}</p> */}
            <div className='images-holder d-flex  align-items-center '>
              <UploadFile
                id={`upload-hotel-image-${generateUniqueID()}`}
                label={Service_Builder?.add_images}
                onChange={(images) => {
                  // handleAddImage(images)
                  handleHotelInputsChanges({ key: 'images', value: images || [] })
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "images", value: images?.length, },
                      { required: true }
                    ),
                  });
                }}
                errorMsg={
                  errors?.images?.required
                    ? inventory.viewReservation.Photos + " " + commons.isRequired
                    : false
                }
                value={hotelData.images}
              />
            </div>
          </div>

        </div>



        {/* Show Pricing Checkbox*/}
        <div className="d-flex align-items-center mb-3">
          <input
            type="Checkbox"
            name={"eheck1"}
            id={"exampleCheck1"}
            checked={showPricing}
            onChange={() => setShowPricing(!showPricing)}
          />

          <label
            className="form-check-label pointer font-bold text-caption mx-2 rememberme-label"
            htmlFor={"exampleCheck1"}
          >
            {Service_Builder?.select_pricing}
          </label>
        </div>

        {/* Hotel Pricing and Taxes*/}
        {showPricing
          &&
          <HotelPricesTaxes
            pricingState={{
              nights_count: hotelData?.nights_count,
              price: hotelData?.price,
              currency: hotelData?.currency,
              taxes: hotelData?.taxes,
            }}
            setPricingState={(data) => {
              // console.log(data)
              setHotelData((prevData) => ({ ...prevData, ...data }))
            }}
          />
        }

        {/* Save Button */}
        <div className="col-12 d-flex justify-content-end gap-10 mt-3">
          <button type="submit" className="submit-btn ">
            <span className="btn-overlay"></span>
            <span className="text d-flex align-items-center gap-10 justify-content-center">
              {status === 'edit' ? Service_Builder?.save_changes : Service_Builder?.save}
            </span>
          </button>
        </div>

      </form>
    </div>
  )
}

export default ServiceBuilderHotelForm