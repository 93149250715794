import Locale from "translations";
import { ReactComponent as LocationIcon } from "assets/images/webBuilder/location-outline-icon.svg";
import { ReactComponent as GoldenStarIcon } from "assets/images/webBuilder/golden-star.svg";
import { ReactComponent as AddIcon } from "assets/images/webBuilder/add-square40.svg";
import usePreviewMode from "hooks/usePreviewMode";
import { useParams, useHistory } from "react-router-dom";
import { useWebBuilderState } from 'context/webBuilder';

export default function HotelCardThemeFive(props) {
  let {
    openEditModal,
    name,
    price,
    image,
    currency,
    stars,
    product_uuid,
    initialName,
    locationName,
    city,
    index
  } = props;
  const { webBuilder } = Locale;
  const { isPreview } = usePreviewMode();
  const { selectedLanguage } = useWebBuilderState();

  const history = useHistory();
  const params = useParams();
  const hotelUrl = `/${params.bundle}/web-builder-v2/preview/hotel/${product_uuid}`;
  let rate = +stars && +stars > 5 ? 5 : +stars;


  return (
    <div className={`package-hotel-card ${!product_uuid ? "empty-service" : ""}`}
      onClick={() => isPreview && history.push(hotelUrl)}
    >
      <span className="rate">
        <GoldenStarIcon />
        {rate}
      </span>
      <div className="card-img">
        {!isPreview && !image ? (
          <button className="btn add-service-btn"
            onClick={() => openEditModal({ ...props, index })}
          >
            <AddIcon />
          </button>
        ) : null}

        {image ? (
          <img src={image} alt="our package" />
        ) : (
          <img
              src="https://back-sbs.staging.safa.asia/api/v1/files/fetch/1847cef6-e5c0-40bb-b1dd-d2dc7827cd58"
            alt="Safa Visa"
          />
        )}
      </div>

      <div className="card-content-top">
        {/* package name and location */}
        <div className="card-details">

          <h4 className="title">{name || initialName}</h4>
          <p className="location">
            <LocationIcon />
            <span className="mx-2">
              {locationName?.[selectedLanguage] || locationName}
              {city && <span>, {city}</span>}

            </span>
          </p>
        </div>
        {/* package price */}
        <div>
          <span>{webBuilder.from}</span>
          <p className="price">
            <span className="amount">{price}</span> {" "}
            <span className="currency">{currency}</span>
          </p>
        </div>
      </div>
    </div>
  )
}