import { useState } from 'react'
// React-Router-Dom
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
// Translation
import Locale from 'translations';
// Components
import SelectField from 'components/Form/SelectField/SelectField'
import TextField from 'components/Form/TextField/TextField'
// Context
import { useSBSState } from 'context/global'
// Assets
import { ReactComponent as AddIcon } from "assets/images/serviceBuilder/orange_add.svg";
import { ReactComponent as TrashIcon } from "assets/images/serviceBuilder/trash.svg";
// -----------------------------------------------------------------------------------------------------

const HotelPricesTaxes = ({ pricingState, setPricingState }) => {

  // *** Hooks
  const { Service_Builder, } = Locale;

  const { allCurrencies, allLookupsBuilder } = useSBSState()

  const { status } = useParams()

  // *** State

  const [showTaxes, setShowTaxes] = useState(pricingState?.taxes?.length > 1 || (status === 'edit' && pricingState?.taxes?.length >= 1) || false);

  const [type, setType] = useState('per_night')

  // *** Function
  const handleAddRoom = () => {
    const newRoomRow = {
      room_type: '',
      adult_price: '',
      child_price: ''
    }
    pricingState.price.push(newRoomRow)
    setPricingState({ ...pricingState })
  }

  const handleRemoveRooms = (index) => {
    // if (pricingState?.price?.length > 1) {
    pricingState?.price?.splice(index, 1)
    setPricingState({ ...pricingState })
    // }
  }

  const handleRoomType = (e, index, name) => {
    const selectedItem = pricingState.price[index]
    // // update selectedItem Name
    selectedItem[`${name}`] = e?.id
    // replace the item with updated name
    pricingState.price[index] = selectedItem
    setPricingState({ ...pricingState, price: pricingState.price })
  }

  const handleRoomPriceChange = (e, index) => {
    const { value, name } = e.target
    const selectedItem = pricingState.price[index]
    // update selectedItem Name
    selectedItem[`${name}`] = value
    pricingState.price[index] = selectedItem
    setPricingState({ ...pricingState, price: pricingState.price })
  }

  const handleServicePriceChange = (key, value) => {
    setPricingState({ ...pricingState, [key]: value });
  }

  const handleAddTaxes = () => {
    const newTaxRow = {
      name: '',
      type: '',
      value: '',
    }
    pricingState?.taxes?.push(newTaxRow)
    setPricingState({ ...pricingState })
  }

  const handleRemoveTaxes = (index) => {
    pricingState?.taxes?.splice(index, 1)
    setPricingState({ ...pricingState })
    if (pricingState?.taxes.length === 0) {
      setShowTaxes(false)
    }
  }

  function handleTaxesTypeChange(e, index, taxType) {
    const { value } = e.target
    const selectedItem = pricingState.taxes[index]
    if (value === 'percentage') {
      selectedItem[`value`] = ''
      selectedItem[`${taxType}`] = value
      pricingState.taxes[index] = selectedItem
      setPricingState({ ...pricingState, taxes: pricingState.taxes })
    } else {
      selectedItem[`value`] = ''
      selectedItem[`${taxType}`] = value
      pricingState.taxes[index] = selectedItem
      setPricingState({ ...pricingState, taxes: pricingState.taxes })
    }
  }

  const handleTaxChange = (e, index) => {
    const { value, name } = e.target
    const selectedItem = pricingState.taxes[index]
    // update selectedItem Name
    selectedItem[`${name}`] = value
    pricingState.taxes[index] = selectedItem
    setPricingState({ ...pricingState })
  }


  // --------- JSX Code --------
  return (
    <div className='pricing-holder'>

      <div className='row px-2 mb-3'>

        <div className='col-md-1 col-2 d-flex  align-items-center' >
          <h2 className='font-weight-bold '>{Service_Builder?.Price}</h2>
        </div>

        {/* Price Per Night  */}
        <div className='col-md-3 col-12' >
          <label
            onClick={() => setType('per_night')}
            htmlFor={`price_per_night`}
            className='taxes-type d-flex align-items-center justify-content-center'
          >
            <input
              type="radio"
              id={`price_per_night`}
              name={`price_type`}
              value="price_per_night"
              checked={type === 'per_night' || pricingState?.nights_count === 1}
              onChange={(e) => setPricingState({ ...pricingState, nights_count: 1 })}
            />
            <p className='taxes-type-value'> {Service_Builder?.price_per_night}</p>
          </label>
        </div>

        {/* Price Per Package  */}
        <div className='col-md-3 col-12' >
          <label
            onClick={() => setType('per_package')}
            htmlFor={`price_per_package`}
            className='taxes-type d-flex align-items-center justify-content-center'
          >
            <input
              type="radio"
              id={`price_per_package`}
              name={`price_type`}
              value="per_package"
              // checked={data?.price_type === 'price_per_package'}
              checked={type === 'per_package' || pricingState?.nights_count > 1}
            />
            <p className='taxes-type-value'>  {Service_Builder?.price_per_package}</p>
          </label>
        </div>

        {/* Night Count  */}
        <div className='col-md-2 col-12' >
          <div className="violation-cost-input">
            <TextField
              disabled={pricingState?.nights_count === 1 && type === 'per_night'}
              hasLabel={false}
              placeholder='3'
              name="nights"
              id="nights"
              value={pricingState?.nights_count}
              type="number"
              onChange={(e) => setPricingState({ ...pricingState, nights_count: e.target.value })}
            />
          </div>
          <div className="violation-cost-select border-0 h-100 d-flex justify-content-end align-items-center">
            <p className='taxes-currency'>{Service_Builder?.night}</p>
          </div>
        </div>

        {/* Currency  */}
        <div className='col-md-2 col-12' >
          <SelectField
            hasLabel={false}
            id="serviceCurrency"
            name="serviceCurrency"
            // value={data?.serviceCurrency?.currency_code || data?.serviceCurrency?.name}
            value={allCurrencies?.find(curr => curr?.currency_code === pricingState?.currency)?.currency_code || pricingState?.currency}
            options={allCurrencies}
            onChange={(selectedCurrency) => handleServicePriceChange("currency", selectedCurrency?.currency_code)}
          />
        </div>

        <div className='col-md-1 col-2 d-flex justify-content-end align-items-center' >
          <button type='button' className='add_btn' onClick={() => handleAddRoom()}>
            <AddIcon />
            {Service_Builder?.add_room}
          </button>
        </div>

      </div>

      {/* Hotel  Rooms  Holder*/}
      {pricingState?.price?.length > 0 &&
        <div className='price-inputs-holder mb-3 '>
          {pricingState?.price?.map((room, index) =>
            <div className='row '>
              <div className="col-md-3 col-12  violation-cost-wrapper">
                <SelectField
                  hasLabel={false}
                  labelInner={true}
                  label={Service_Builder?.room_type}
                  placeholder={Service_Builder?.room_type}
                  id="room_type"
                  name="room_type"
                  value={allLookupsBuilder?.room_types?.find(item => item?.id === room?.room_type)?.name}
                  options={allLookupsBuilder?.room_types}
                  onChange={(e) => handleRoomType(e, index, "room_type")}
                />
              </div>

              <div className="col-md-4 col-12  violation-cost-wrapper">
                <div className="violation-cost-input">
                  <TextField
                    hasLabel={false}
                    labelInner={true}
                    label={Service_Builder?.adult_price}
                    placeholder={Service_Builder?.adult_price}
                    name="adult_price"
                    id="adult_price"
                    value={room?.adult_price}
                    type="number"
                    onChange={(e) => handleRoomPriceChange(e, index)}
                  />
                </div>
                <div className="violation-cost-select h-100 d-flex justify-content-end align-items-center">
                  <p className='taxes-currency'>{pricingState?.currency}</p>
                </div>
              </div>

              <div className="col-md-4 col-12 violation-cost-wrapper">
                <div className="violation-cost-input">
                  <TextField
                    hasLabel={false}
                    labelInner={true}
                    label={Service_Builder?.child_price}
                    placeholder={Service_Builder?.child_price}
                    name="child_price"
                    id="child_price"
                    value={room?.child_price}
                    type="number"
                    onChange={(e) => handleRoomPriceChange(e, index)}
                  />
                </div>
                <div className="violation-cost-select h-100 d-flex justify-content-end align-items-center">
                  <p className='taxes-currency'>{pricingState?.currency}</p>
                </div>
              </div>

              <div className='col ' >
                <span
                  className='d-flex justify-content-center pointer-event align-items-center h-100'
                  onClick={() => handleRemoveRooms(index)}
                >
                  <TrashIcon />
                </span>
              </div>

            </div>
          )}
        </div>
      }

      {/* Select Taxes  and add Button*/}
      <div className='d-flex mb-3 justify-content-between align-items-center'>

        <div className="d-flex align-items-center">
          <input
            type="Checkbox"
            name={"eheck1"}
            id={"taxes"}
            checked={showTaxes}
            onChange={() => setShowTaxes(!showTaxes)}
          />

          <label
            className="form-check-label font-bold  pointer text-caption mx-2 rememberme-label"
            htmlFor={"taxes"}
          >
            {Service_Builder?.taxes}
          </label>
        </div>

        {showTaxes &&
          <button type='button' className='add_btn' onClick={() => handleAddTaxes()}>
            <AddIcon />
            {Service_Builder?.add_taxes}
          </button>
        }
      </div>

      {/* Taxes Row */}
      {(showTaxes && pricingState?.taxes?.length > 0) &&
        <div className='taxes-holder '>
          {pricingState?.taxes?.map((tax, index) => (
            <div key={index} className='row px-3 '>

              {/* tax name input */}
              <div className='col-12 custom-field col-lg-3 '>
                <TextField
                  hasLabel={false}
                  placeholder={Service_Builder?.tax_name}
                  name={"name"}
                  id="name"
                  value={pricingState?.taxes[index]?.name}
                  type="text"
                  onChange={(e) => handleTaxChange(e, index)}
                />
              </div>

              {/* percentage radio input */}
              <div className='col-5 col-lg-2  '>
                <label htmlFor={`fixed_price_${index}`} className='taxes-type d-flex align-items-center justify-content-center'>
                  <input
                    type="radio"
                    id={`fixed_price_${index}`}
                    name={`type${index}`}
                    value="fixed"
                    checked={tax?.type === 'fixed'}
                    onChange={(e) => handleTaxesTypeChange(e, index, 'type')}
                  />
                  <p className='taxes-type-value'>  {Service_Builder?.fixed_price}</p>
                </label>
              </div>

              {/* fixed Price input */}
              <div className='col-5 col-lg-2 custom-field' >
                <div className="violation-cost-wrapper">
                  <div className="violation-cost-input">
                    <TextField
                      hasLabel={false}
                      disabled={tax?.type !== 'fixed'}
                      placeholder={"1000"}
                      name="value"
                      id="taxes_price"
                      value={pricingState?.taxes?.find((tax, taxIndex) => (tax?.type === 'fixed' && taxIndex === index))?.value}
                      type="number"
                      onChange={(e) => handleTaxChange(e, index)}
                    />
                  </div>
                  <div className="violation-cost-select h-100 d-flex justify-content-end align-items-center">
                    <p className='taxes-currency'>{pricingState?.currency}</p>
                  </div>
                </div>
              </div>

              {/* percentage radio input */}
              <div className='col-5 col-lg-2 ' >
                <label htmlFor={`percentage_${index}`} className='taxes-type d-flex align-items-center justify-content-center'>
                  <input
                    type="radio"
                    id={`percentage_${index}`}
                    name={`type${index}`}
                    value={"percentage"}
                    checked={tax?.type === 'percentage'}
                    onChange={(e) => handleTaxesTypeChange(e, index, 'type')}
                  />
                  <p className='taxes-type-value'>  {Service_Builder?.percentage}</p>
                </label>
              </div>

              {/* percentage input */}
              <div className='col-5 col-lg-2 custom-field ' >
                <div className="violation-cost-wrapper">
                  <div className="violation-cost-input">
                    <TextField
                      hasLabel={false}
                      placeholder={'10 %'}
                      name="value"
                      disabled={tax?.type !== 'percentage'}
                      id="taxes_percentage"
                      value={pricingState?.taxes?.find((tax, taxIndex) => ((tax?.type === 'percentage') && (taxIndex === index)))?.value}
                      type="number"
                      onChange={(e) => handleTaxChange(e, index)}
                    />
                    <div className="violation-cost-select h-100 d-flex justify-content-end align-items-center">
                      <p className='taxes-currency'>%</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* delete btn */}
              <div className='col ' >
                <span
                  className='d-flex justify-content-center pointer-event align-items-center h-100'
                  onClick={() => handleRemoveTaxes(index)}
                >
                  <TrashIcon />
                </span>
              </div>

            </div>
          ))}
        </div>
      }

    </div>
  )
}

export default HotelPricesTaxes